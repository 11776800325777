import { AngelatemplateConfigClass } from './../../../../object/templateClass';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { MenuClass } from 'src/app/object/headMenuClass';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class AngelaService {
  AngelaInit = false

  private headerMenuInit = false;
  public headerMenu: Array<MenuClass> = [];
  public settings_ = new BehaviorSubject<AngelatemplateConfigClass>(new AngelatemplateConfigClass(4));

  constructor(
    private _api: ApiService
  ) {  
    this.settings_.value.ReturnFromDataBase('template_settings', ['template_id'], [String(4)]).then(data=>{
    }).catch(data=>{
      console.log(data)
    });
  }
  public resetData(): void {
    this.headerMenu = [];
    this.headerMenuInit = false;
  }



  public returnMenu(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.headerMenuInit === false) {
        this.headerMenuInit = true;
        this._api.GetFromDataBase(null, 'header_menu', [], [], [])
          .then((data: Array<MenuClass>) => {
            const database: Array<any> = [];
            data.forEach((element: MenuClass) => {
              const tmp = new MenuClass(element.id, element.name, element.position, element.parent_id, element.mode, element.menuModeValue);
              database.push(tmp);
            });
            database.forEach(element => {   // wpadkowanie submenu do tablic w obiektach menu
              if (element.parent_id == 0) {
                this.headerMenu.push(element);
              }
            });
            database.forEach(element => {
              if (element.parent_id != 0) {
                this.headerMenu.filter(filterElement => {
                  if (filterElement.id == element.parent_id) {
                    filterElement.addchildrenObject(element);
                  }
                });
              }
            });
              resolve(this.headerMenu);
          })
          .catch(value => {
            reject(false);
          });
      } else {
        resolve(this.headerMenu);
      }
    });
  }


}
