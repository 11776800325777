import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PatiotemplateConfigClass } from 'src/app/object/templateClass';

@Injectable({
  providedIn: 'root'
})
export class PatioService {
  public onePixelImage= 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
  public settings_ = new BehaviorSubject<PatiotemplateConfigClass>(new PatiotemplateConfigClass(5));
  public currentPage = 1;
  public currentPage_category = 1;
  constructor(

 ) {
    this.settings_.value.ReturnFromDataBase('template_settings', ['template_id'], [String(5)]).then(data=>{
    }).catch(data=>{
      console.log(data)
    });
   }
}
